import React from 'react';
import styled from 'styled-components';
import { useStaticQuery, graphql } from 'gatsby';
import { MarkdownRenderer } from '../../components/markdown-renderer';

import { Props, ChildMarkdownRemark } from '../../types/basicTypes';
import { Layout } from '../../components';
import { MEDIA_QUERY } from '../../commonStyles';

const termsOfUseData = (language: string) => {
  const { allContentfulTermsOfUse } = useStaticQuery(graphql`
    query TermsOfUseQuery {
      allContentfulTermsOfUse {
        nodes {
          node_locale
          title
          info {
            childMarkdownRemark {
              html
            }
            info
          }
        }
      }
    }
  `);
  return allContentfulTermsOfUse.nodes.find(
    ({ node_locale: nodeLocale }: { node_locale: string }) =>
      nodeLocale === language
  );
};

type Info = {
  info: string;
  childMarkdownRemark: ChildMarkdownRemark;
};

type TermsOfService = {
  title: string;
  info: Info;
};

const TermsOfUse: React.FC<Props> = ({ pageContext: { language } }) => {
  const { title, info }: TermsOfService = termsOfUseData(language);

  return (
    <Layout title={title} language={language} hideFooterBox>
      <Root>
        <Container>
          <TitleContainer>{title}</TitleContainer>
          <StyledMarkdownRenderer markdown={info.info} />
        </Container>
      </Root>
    </Layout>
  );
};

const Root = styled.div`
  margin: 0 auto;
  max-width: 1248px;
  flex: 1 100%;
  display: flex;
  flex-direction: column;
  margin: 0 auto;
  padding: 52px 30px 0 30px;
  ${MEDIA_QUERY.TABLET} {
    padding-top: 44px;
  }
  ${MEDIA_QUERY.MOBILE} {
    padding-top: 28px;
  }
`;

const Container = styled.div`
  max-width: 992px;
  width: 100%;
  padding: 0 110px;
  margin: 0 auto;
  flex: 1 100%;
  ${MEDIA_QUERY.TABLET} {
    width: auto;
  }
  ${MEDIA_QUERY.MOBILE} {
    padding: 0;
    width: auto;
  }
`;

const TitleContainer = styled.div`
  font-family: Arial;
  font-size: 44px;
  font-weight: bold;
  line-height: 1.23;
  color: #232425;
  text-align: left;
  max-width: 608px;
  margin: 0 0 auto 0;
  ${MEDIA_QUERY.TABLET} {
    font-size: 36px;
    max-width: 448px;
    margin: 0 auto;
  }
  ${MEDIA_QUERY.MOBILE} {
    font-size: 24px;
    max-width: 448px;
    margin: 0 auto;
  }
`;

const StyledMarkdownRenderer = styled(MarkdownRenderer)`
  color: #232425;
  max-width: 608px;
  margin: 0 0 auto 0;
  ${MEDIA_QUERY.TABLET} {
    max-width: 448px;
    margin: 0 auto;
  }
  ${MEDIA_QUERY.MOBILE} {
    max-width: 448px;
    margin: 0 auto;
  }
`;
export default TermsOfUse;
